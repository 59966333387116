import React from 'react';
import './BillList.css';

import { useNavigate } from "react-router-dom";

class BillList extends React.Component {

    constructor() {

        super();

        this.state = {
            data: [],
            loading: true,
            error: null,
            index: 0,
            count: 99999999,
            numberOfRecords: 0,
            buttonFormat: 0,
            searchTerm: '',
            minPriceTotal: 0,
            allData: [] // Store all fetched data
        };
        
        this.fetchAllData = this.fetchAllData.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.changeFormat = this.changeFormat.bind(this);
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
        this.handleMinPriceChange = this.handleMinPriceChange.bind(this);
       }

    componentDidMount() {
        this.fetchAllData(); 
        this.fetchData();
    }

    fetchData() {
        var { index, count } = this.state;
		var companyID = this.props.companyID;
		var year = 2024;
		
		var url = "https://dashboardapi.plurato.com/getbilllist?companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&sortby=billID&sortasc=1";
		url = url + "&index=" + index;
		url = url + "&count=" + count;
		
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ data, loading: false });
            })
            .catch((error) => {
                this.setState({ error: error.message, loading: false });
            });
    }

    fetchAllData() {

        var index = 0;
		var count = 99999999999;
		var companyID = this.props.companyID;
		var year = 2024;

		var url = "https://dashboardapi.plurato.com/getbilllist?companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&sortby=billID&sortasc=1";
		url = url + "&index=" + index;
		url = url + "&count=" + count;

        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ allData: data, numberOfRecords: data.length });
            })
            .catch((error) => {
                this.setState({ error: error.message, loading: false });
            });
    }

    handleSearchTermChange(e) {
        this.setState({ searchTerm: e.target.value, index: 0 });
    }

    handleMinPriceChange(e) {
        var minPrice = parseFloat(e.target.value);
        console.log(minPrice)
        this.setState({ minPriceTotal: minPrice, index: 0 });
    }

    changeFormat() {
        this.setState((prevState) => {
            var newButtonFormat = prevState.buttonFormat === 0 ? 1 : 0;

            return {
                buttonFormat: newButtonFormat,
            };
        }, this.fetchData);
    }

    filteredData() {
        var { allData, searchTerm, minPriceTotal } = this.state;

        // Filter based on all data
        return allData.filter(item => 
            item.priceTotal >= minPriceTotal && 
            item.sellerName.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }
	
	onBillClick(itemObj) {
		var billAutoID = itemObj.billAutoID;
		this.props.setSelectedBillAutoID(billAutoID);
		const navigate = this.props.navigate;
  		navigate("/bills/view");
	}

    render() {

        var { loading, error, buttonFormat, index, count, searchTerm, minPriceTotal } = this.state;

        if (loading) {
            return <p>Loading...</p>;
        }

        if (error) {
            return <p>Error: {error}</p>;
        }

        // Get filtered data
        var filteredData = this.filteredData();
        // Slicing the filtered data for pagination
        var displayedData = filteredData.slice(index, index + count);

        return (
            <div className="main-container">
                <button className="changeFormat" onClick={this.changeFormat}>Change Format</button>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={this.handleSearchTermChange}
                    style={{ padding: '8px', width: '200px' }}
                />
                <input
                    type="number"
                    placeholder="Min Total Price..."
                    value={minPriceTotal}
                    onChange={this.handleMinPriceChange}
                    style={{ padding: '8px', width: '200px' }}
                />
                <div className={buttonFormat === 0 ? 'button-container0' : 'button-container1'}>
                    {
                        displayedData.map((item) => (
                            <button
                                key={item.id}
                                className={buttonFormat === 0 ? 'billButton0' : 'billButton1'}
                                onClick={() => this.onBillClick(item)}
                            >
								<span className={buttonFormat === 0 ? 'seller-name0' : 'seller-name1'}>{item.sellerName} / {item.sellerNameERP}</span>
                                <span className={buttonFormat === 0 ? 'total-price0' : 'total-price1'}>{item.priceTotal} / {item.priceTotalERP}</span>
                                <span className={buttonFormat === 0 ? 'date0' : 'date1'}>
                                    Bill ID: {item.billID}
                                </span>
                                <span className={buttonFormat === 0 ? 'date0' : 'date1'}>
                                    {item.issueDateYear} {item.issueDateMonth} {item.issueDateDay}
                                </span>
                            </button>
                        ))
                    } 
                </div>
            </div>
        );
    }  
}

export default function BillListFunction(props) {
	const navigate = useNavigate();
	return <BillList {...props} navigate={navigate} />;
}

