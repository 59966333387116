import React  from 'react';
import './App.css';
import { AiOutlineDownload, AiOutlineDelete } from "react-icons/ai"
import { CgRename } from "react-icons/cg"
import { BiEdit } from "react-icons/bi"
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dropzone from 'react-dropzone';
import './BillNewMulti.css';

import { useNavigate } from "react-router-dom";

class BillNewMulti extends React.Component {

	constructor() {

		super();

		this.state = {
			uploadInProgressYN: false,
			fileIndex: -1,
			fileArray: [],
		};

		this.onDrop = async (files) => {

			this.state = {
				fileArray: [],
			};

			var len = files.length;
			
			if (len < 1) {
				this.props.showToast("ERROR - You must select at least one file.");
				return;
			}

			var i;
			var oneFile;
			var sizeBytes;
			var filenameExt;
			var results;
			var filename;
			var fileExt;
			var itemObj;
			var fileArray = [];
			
			for(i=0; i<len; i++) {

				oneFile = files[i];
				sizeBytes = oneFile.size;
				filenameExt = oneFile.name;
				results = this.getFilenamePartsFromFilenameExt(filenameExt);
				filename = results.filename;
				fileExt = results.fileExt;

				if (fileExt !== "pdf") {
					this.props.showToast("ERROR - You must select only PDF files.");
					return;
				}
				
				var billID = parseInt(filename);			
				var nanYN = isNaN(billID);
				
				if (nanYN) {
					//this.props.showToast("ERROR - You must select file with this format: billNumber.pdf");
				}
				
				itemObj = {};
				itemObj.file = oneFile;
				itemObj.filenameExt = filenameExt;
				itemObj.billID = billID;
				itemObj.sizeBytes = sizeBytes;
				itemObj.duplicateYN = false;
				itemObj.uploadedYN = false;
				
				fileArray.push(itemObj);
			}
			
			fileArray = await this.checkIfAllBillsAlreadyExist(fileArray);
			
			this.setState({
				fileArray: fileArray
			});
			
			var duplicateYN = false;
			var fileIndex = -1;
			
			for(i=0; i<len; i++) {
				itemObj = fileArray[i];
				duplicateYN = itemObj.duplicateYN;
				if (!duplicateYN) {
					fileIndex = i;
					this.uploadOneBill(fileArray, fileIndex);
					return;
				}
			}
		};

		this.getFilenamePartsFromFilenameExt = this.getFilenamePartsFromFilenameExt.bind(this);
		this.uploadOneBill = this.uploadOneBill.bind(this);
		this.getExtFromFilename = this.getExtFromFilename.bind(this);
	}
	
	componentDidMount() {
	}
	
	async checkIfOneBillAlreadyExists(billID) {

		var companyID = this.props.companyID;
		var year = 2024;
		var baseURLAPI = this.props.baseURLAPI;		
		var url = baseURLAPI + "/getbilldatabybillid?companyid=" + companyID;
		url = url + "&year=" + year;
		url = url + "&billid=" + billID;
				
		var jsonObj = null;

		try {
			var res = await fetch(url);
			jsonObj = await res.json();
		} catch (err) {
			return false;
		}
		
		var existsYN = false;
		var pdfYN = jsonObj.pdfYN;
		if (pdfYN) {
			existsYN = true;
		}

		return existsYN;
	}

	async checkIfAllBillsAlreadyExist(fileArray) {

		var i;
		var itemObj;
		var billID;
		var duplicateYN;
		
		for(i=0; i<fileArray.length; i++) {
			itemObj = fileArray[i];
			billID = itemObj.billID;
			duplicateYN = await this.checkIfOneBillAlreadyExists(billID);
			itemObj.duplicateYN = duplicateYN;
		}

		return fileArray;
	}

	getFilenamePartsFromFilenameExt(filenameExt) {

		var i;
		var ch;
		var filename = "";
		var fileExt = "";
		var length = filenameExt.length;
		var result = {};

		for(i=length-1; i>=0; i--) {
			ch = filenameExt.charAt(i);
			if (ch === ".") {
				filename = filenameExt.substring(0, i);
				fileExt = filenameExt.substring(i+1, length);
				result.filename = filename;
				result.fileExt = fileExt.toLowerCase();
				return result;
			}
		}
		
		filename = filenameExt;
		fileExt = "";
		result.filename = filename;
		result.fileExt = fileExt;
		return result;
	}

	getExtFromFilename(filename) {
		var ext = filename.substr(filename.lastIndexOf('.') + 1);
		return ext.toLowerCase();
	}

	async uploadOneBill(fileArray, index) {
		
		console.log("uploadOneBill - index = " + index);
		
		this.setState({
			uploadInProgressYN: true,
			fileIndex: index,
		});

		var baseURLAPI = this.props.baseURLAPI;
		var userID = this.props.userID;
		var fullName = this.props.fullName;
		var companyID = this.props.companyID;
		
		var itemObj = fileArray[index];
		console.log(itemObj);
		var file = itemObj.file;
		var billID = itemObj.billID;
		var sizeBytes = itemObj.sizeBytes;
		var year = 2024;
		
		var url = baseURLAPI + "/uploadbill?year=" + year;
		url = url + "&billid=" + billID;
		url = url + "&sizebytes=" + sizeBytes;
		url = url + "&userid=" + userID;
		url = url + "&fullname=" + encodeURIComponent(fullName);
		url = url + "&companyid=" + companyID;
				
		var formData = new FormData()
		formData.append('file', file)

		var jsonObj = null;

		try {
			var res = await fetch(url, {
				method: 'POST',
				body: formData
			});

			jsonObj = await res.json();
		} catch (err) {
		}
		
		if (jsonObj === null) {
			this.props.showToast("ERROR - Unknown error occured");
			return;
		}

		var rt = jsonObj.rt;

		if (rt === 1) {
			this.props.showToast("ERROR - Bill with same number already exists");
			return;
		}
		
		fileArray[index].uploadedYN = true;
		this.setState({
			fileArray: fileArray,
		});

		var i;
		var duplicateYN;
		var itemObj;
		var fileIndex;

		for(i=index+1; i<fileArray.length; i++) {
			itemObj = fileArray[i];
			duplicateYN = itemObj.duplicateYN;
			if (!duplicateYN) {
				fileIndex = i;
				this.uploadOneBill(fileArray, fileIndex);
				return;
			}
		}

		this.setState({
			uploadInProgressYN: false,
		});
	}
	
	renderUploadInProgress() {

		var uploadInProgressYN = this.state.uploadInProgressYN;
		if (!uploadInProgressYN) {
			return null;
		}
		
		var fileIndex = this.state.fileIndex;
		var fileArray = this.state.fileArray;
		var len = fileArray.length;
		var text1 = (fileIndex + 1) + "/" + len;
		
		return (
			<div>
				<div>Bill upload in progress: {text1}</div>
				<div style={{height: "10px"}}></div>
			</div>
		);
	}
	
	renderOneFile(index, itemObj) {

		var filenameExt = itemObj.filenameExt;
		var sizeBytes = itemObj.sizeBytes;
		var duplicateYN = itemObj.duplicateYN;
		var uploadedYN = itemObj.uploadedYN;
		var fileText = "";
		
		fileText = "waiting";

		if (duplicateYN) {
			fileText = "PDF with this Bill ID already uploaded";
		}

		if (uploadedYN) {
			fileText = "PDF uploaded";
		}

		return (
			<div key={index}>
				{filenameExt} - {sizeBytes} bytes - {fileText}
			</div>
		);
	}
	
	renderFiles() {
		
		var fileArray = this.state.fileArray;
		var len = fileArray.length;
		
		if (len < 1) {
			return null;
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<len; i++) {
			itemObj = fileArray[i];
			jsxCode = this.renderOneFile(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	render() {

		return (
			<div>
				<div style={{height: "10px"}}></div>
				{this.renderUploadInProgress()}
				<Dropzone onDrop={this.onDrop}>
				{({getRootProps, getInputProps}) => (
				  <section>
					<div {...getRootProps({className: 'dropzone'})}>
					  <input {...getInputProps()} />
					  <div className="container">Drag 'n' drop multiple PDF files here, or click to select them</div>
					</div>
					<div style={{height: "15px"}}></div>
				  </section>
				)}
				</Dropzone>
				<div style={{height: "10px"}}></div>
				{this.renderFiles()}
				<div style={{height: "10px"}}></div>
			</div>
		);
	}
}

export default function BillNewMultiFunction(props) {
	const navigate = useNavigate();
	return <BillNewMulti {...props} navigate={navigate} />;
}

